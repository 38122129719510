/* App.css */
.content {
  transition: all 0.3s ease-in-out;
}

.content.withSidebar {
  margin-left: 250px;
}

.content.noSidebar {
  margin-left: 0;
}

/* Loading.css */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
